import React from 'react';
import './Contact.css';
import contactImg from "../../img/about-9.jpg"
import { useState } from 'react';
import { send } from 'emailjs-com';

function Contact() {
    const initialState = {
        full_name: '',
        email: '',
        subject: '',
        message: '',
      }
    const [toSend, setToSend] = useState(initialState);

    const handleSubmit = (event) => {
        event.preventDefault();
        // send(
        //     'service_bf8ej08',
        //     'template_zu415nj',
        //     toSend,
        //     'user_nSc7oDKz2iAhCdwhjzZNL'
        // )
        //     .then((response) => {
        //     alert('SUCCESS! Email was successfully received');
        //     })
        //     .catch((err) => {
        //     alert('SUCCESS! There was a problem sending email');
        //     });
        setToSend(initialState);
    };
    const handleChange = (e) => {
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    return (
        <div className="contact component__space" id="Contact">
            <div className="row">
                <div className="col__2">
                    <div className="contact__box">
                        <div className="contact__meta">
                            <h1 className="hire__text">Hire Me.</h1>
                            <p className="hire__text white">I am available for freelance and contract work. Feel free to connect with me:</p>
                           <p className="hire__text white">phone <strong>+977 9842875805</strong> or email <strong>aavashkhatri95@gmail.com</strong></p>
                        </div>
                        <form className="input__box" method="POST" action="/" onSubmit={e => handleSubmit(e)}>
                           <input type="text" 
                           required
                           name='full_name'
                           value={toSend.full_name} 
                           onChange={handleChange}
                           className="contact name" placeholder="Full Name" />
                           <input type="text" 
                           required
                           name='email'
                           value={toSend.email} 
                           onChange={handleChange}
                           type="email"
                           className="contact email" placeholder="Email" />
                           <input type="text" 
                           required
                           name='subject'
                           value={toSend.subject} 
                           onChange={handleChange}
                           className="contact subject" placeholder="Subject" />
                           <textarea
                           required
                           name='message'
                           value={toSend.message} 
                           onChange={handleChange}
                           id="message" placeholder="Your message"></textarea>
                           <button className="btn contact pointer" type="submit" text="Submit">Submit</button>
                        </form>
                    </div>
                </div>
                <div className="col__2">
                    <img src={contactImg} alt="" className="contact__img" />
                </div>
            </div>
        </div>
    )
}

export default Contact
